import React from 'react';
import './styles.scss';

const VerticalDonationBar = ({ currentAmount, totalAmount }) => {
  const donationProgress = 100 * (currentAmount / totalAmount);
  const currentProgress = `${donationProgress * 0.665}%`; // to offset progress bar style

  const formatToCurrency = amount => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <div className="progress-container">
      <div className="progress-text goal-amount">${formatToCurrency(totalAmount)}</div>
      <div className="progress-text half-amount">${formatToCurrency(totalAmount / 2)}</div>
      <div className="progress-bar" style={{ height: currentProgress }} />
    </div>
  );
};

export default VerticalDonationBar;
